export enum FundType {
  CLOSED_END = 'CLOSED_END',
  OPEN_END = 'OPEN_END',
  DIRECT_DEAL = 'DIRECT_DEAL',
}

export interface InvestmentEntity {
  id: number
  name: string
  email?: string
  userId?: number
}
export interface InvestmentFund extends WithCmsInvestmentId {
  fundId: number // gen2fund.id
  name: string
  legalName: string
  fundType: FundType
  assetClass: string
}

export interface MetricsSummary {
  totalNetAssetsValue: number
  totalInvestedCapital: number
  totalCommittedCapital: number
  totalRemainingUncalledCapital: number
}

export interface AssetAllocation {
  assetClass: string
  allocation: number
  percent: number
}

export interface EntityMetrics {
  investedEntity: string
  investedEntityId?: number
  fundType?: FundType
  currentBalance: number
  investedCapital: number
  committedCapital?: number
  calledPercent?: number
  remainingUncalled?: number
  uncalledPercent?: number
}

export interface DetailedMetrics extends WithCmsInvestmentId {
  assetClass: string
  fundLegalName: string
  fundName: string
  fundId: number
  investedEntity: string
  investedEntityId: number
  fundType?: FundType
  shareClass: string
  shareClassId: number
  asOfDate: string
  currentBalance: number
  investedCapital: number
  committedCapital?: number
  distributions?: number
  redemptions?: number
  calledCapitalPercent?: number
  DPI?: number
  TVPI?: number
  MOIC?: number
  IRR?: number
  YTD?: number
  ITD?: number
}

export interface DetailedMetricsWithInvestmentLogo extends DetailedMetrics, WithInvestmentLogo {}

export interface CapitalActivity extends WithCmsInvestmentId {
  transactionId: number
  investedEntity: string
  investedEntityId: number
  fundId: number
  fundName: string
  fundLegalName: string
  shareClass: string
  shareClassId: number
  date: string
  activityType: string
  amount: number
}

export interface WithCmsInvestmentId {
  cmsInvestmentId?: number
}

export interface WithInvestmentLogo {
  investmentOpportunityLogo?: string
}

export interface CapitalActivityWithInvestmentLogo extends CapitalActivity, WithInvestmentLogo {}

export interface PeriodReturnMetrics {
  reportingPeriod: string

  fundId: number
  fundType: FundType
  fundName: string
  fundLegalName: string

  investedEntityId: number
  investedEntity: string

  shareClassId: number
  shareClass: string

  beginningBalance: number
  netIncome: number
  endingBalance: number

  // OPEN END
  additions?: number
  redemptions?: number
  MTD?: number // percent
  YTD?: number // percent
  ITD?: number // percent

  // CLOSED_END
  contributions?: number
  distributions?: number
  DPI?: number
  TVPI?: number

  // CLOSED_END and DIRECT_DEAL
  MOIC?: number
  IRR?: number // percent
}
